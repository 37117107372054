div.modal-effect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 60%;
  border: 1px solid transparent;
  background-color: #2b2929;
  opacity: 0.8;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  p {
    font-size: 20px;
    color: #efefef;
    text-align: center;
  }
}
