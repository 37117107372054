div.section-5-container {
  height: 1025px;
  width: 100%;
  background-color: #061019;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 1300px) {
    height: auto !important;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  div.left-panel {
    height: 70%;
    width: 40%;
    min-width: 370px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-right: 5%;
    text-align: left;

    @media only screen and (max-width: 1300px) {
      height: auto;
      padding-right: 0;
    }

    h1 {
      font-weight: 700;
      font-size: 46px;
      color: #efefef;

      &.description {
        width: 95%;
        margin: 1% 0 1% 0;
        font-weight: normal;
        font-size: 20px;

        @media only screen and (max-width: 1300px) {
          font-size: 13px;
        }

        @media only screen and (max-width: 900px) {
          width: 100%;
        }
      }
    }
    @media screen and (max-width: 820px) {
      text-align: center;
    }
  }

  div.right-panel {
    height: 70%;
    width: 50%;
    min-width: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    display: flex;
    justify-content: center;

    @media only screen and (max-width: 1300px) {
      background-size: 85% auto;
      height: 400px;
    }

    div {
      display: flex;
      flex-direction: column;
      width: 45%;
      height: auto;
      margin-top: 28%;
      margin-left: 43%;

      @media only screen and (max-width: 1300px) {
        margin-top: 23%;
      }

      span {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #000;
        width: 100%;
        height: auto;
        padding: 18px 20px 18px 20px;
        margin-bottom: 3%;
        border-radius: 10px;
        background-color: #c1f792;

        &.even {
          background-color: #f3f3f3;
        }

        @media only screen and (max-width: 1300px) {
          padding: 5px 10px 5px 10px;
          width: 85%;
        }

        p {
          font-weight: bold;
          font-size: 18px;

          @media only screen and (max-width: 1300px) {
            font-size: 10px;
          }
        }
      }
    }
  }
}
