div.section-7-container {
  height: auto;
  width: 100%;
  background-color: #3da902;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 5%;

  @media only screen and (max-width: 950px) {
    height: auto !important;
  }

  h1,
  p {
    color: #fff;
    text-align: center;

    &.hidden {
      display: none;
    }
  }

  h1 {
    font-size: 50px;

    &.description {
      width: 95%;
      margin: 1% 0 1% 0;
      font-weight: normal;
      font-size: 20px;

      @media only screen and (max-width: 1300px) {
        font-size: 13px;
      }

      @media only screen and (max-width: 950px) {
        width: 100%;
      }
    }

    &.second-header {
      margin: 10% 0 2.5% 0;
    }
  }

  p {
    font-weight: bold;
    font-size: 20px;
    width: 55%;
    margin-top: 1%;
    margin-bottom: 1%;

    @media only screen and (max-width: 950px) {
      font-size: 13px !important;
      width: 90%;
    }
  }

  div.roadmap-list {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      height: auto;
      width: 70%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      padding: 5% 0 5% 0;

      @media only screen and (max-width: 950px) {
        height: 100px;
        width: 350px;
        justify-content: center;
      }

      p {
        color: #000;
        text-align: justify;
        margin: 0 0 0 23%;
        font-size: 20px;
        width: 74%;
        font-weight: 100;

        &.even {
          margin: 0 23% 0 6%;
        }

        &.title {
          font-weight: bold;
          font-size: 25px;
        }

        strong {
          color: #3da902;

          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  div.gallery-thumbnails {
    min-height: 600px;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    @media only screen and (max-width: 950px) {
      min-height: 200px;
    }

    img {
      height: auto;
      width: 100%;
      max-width: 271px;

      @media only screen and (max-width: 950px) {
        max-width: 79px;
      }
    }
  }
}
