div.section-1-container {
  background-color: #f7d536;
  height: auto;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;

  @media only screen and (max-width: 650px) {
    padding-top: 2%;
  }

  img {
    height: 100%;
    width: 100%;

    @media only screen and (max-width: 650px) {
      max-height: 49px !important;
      max-width: 122px !important;
    }
  }

  div.group-info {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 35%;
    left: 5%;
    height: 100%;
    width: 40%;
    max-height: 400px;
    justify-content: space-evenly;

    @media only screen and (max-width: 650px) {
      top: 20%;
      width: 60%;
      max-height: 190px;
    }

    h1 {
      &.main-header {
        font-size: 52px;
        line-height: 65px;

        @media only screen and (max-width: 650px) {
          font-size: 20px;
          line-height: 30px;
        }
      }

      &.sub-header {
        width: 70%;
        @media only screen and (max-width: 650px) {
          width: 80%;
        }
      }
    }

    button {
      background-image: linear-gradient(
        -225deg,
        #ffa800,
        #ffa101,
        #ff8a02,
        #ff8602
      );
      text-transform: none;
      height: 60px;
      width: 100%;
      min-width: 10px;
      max-width: 260px;

      @media only screen and (max-width: 650px) {
        height: 33px;
        width: 70%;
      }
    }
  }
}
.mint-btn {
  color: white !important;
}

.MuiPaper-elevation0 button {
  height: fit-content !important;
}
