div.section-2-container {
  background-color: #f7d536;
  height: auto;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8%;

  @media only screen and (max-width: 650px) {
    padding-top: 3%;
    height: auto !important;
    padding-bottom: 5%;
  }

  h1 {
    font-size: 40px;

    &.sub-header {
      font-size: 23px;
    }

    &.description {
      text-align: center;
      width: 55%;
      margin-top: 1%;
      margin-bottom: 1%;
      font-weight: normal;
      font-size: 20px;

      @media only screen and (max-width: 650px) {
        width: 85%;
      }
    }
  }

  div.carousel-handler {
    height: 40%;
    width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;

    div.alice-carousel {
      div.alice-carousel__wrapper {
        width: 94.2%;
        margin-left: 30px;

        @media only screen and (max-width: 650px) {
          width: 80%;
        }

        ul {
          li {
            div {
              height: 250px;
              width: 250px;
              padding-left: 15px;
              padding-right: 15px;

              img {
                border: 4px solid black;
                border-radius: 10px;
                height: 80%;
                width: 100%;
              }

              p {
                text-align: center;
                font-weight: bold;
                font-size: 18px;
                margin-top: 8px;
              }
            }
          }
        }
      }

      ul.alice-carousel__dots {
        margin: 0;
      }

      div.alice-carousel__prev-btn {
        position: absolute;
        width: auto;
        top: 30%;
      }

      div.alice-carousel__next-btn {
        position: absolute;
        width: auto;
        top: 30%;
        right: 0;
      }
    }
  }

  button {
    background-image: linear-gradient(-225deg, #0078d9, #0064b6, #01437d);
    height: 65px;
    width: 190px;

    @media only screen and (max-width: 650px) {
      height: 50px;
      width: 120px;
    }
  }
}
