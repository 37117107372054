div.section-3-container {
  height: auto;
  width: 100%;
  background-color: #1b4801;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 8% 0 13% 0;

  @media only screen and (max-width: 1300px) {
    height: auto !important;
  }

  h1 {
    font-size: 40px;
    color: #fff;

    &.sub-header {
      font-size: 23px;
    }

    &.description {
      text-align: center;
      width: 50%;
      margin: 1% 0 1% 0;
      font-weight: normal;
      font-size: 20px;

      @media only screen and (max-width: 1300px) {
        width: 85%;
      }
    }
  }

  div.category-handler {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: auto;
    width: 85%;

    div {
      filter: drop-shadow(0 0 0.75rem #0b2600);

      @media only screen and (max-width: 1300px) {
        padding: 5px;
        display: flex;
        justify-content: center;
      }

      img {
        height: 100%;
        width: 100%;
        transition: all 0.3s ease-in-out;

        &:hover {
          cursor: pointer;
          transform: scale(1.1);
        }

        @media only screen and (max-width: 1300px) {
          width: 75%;
        }
      }
    }
  }
}
